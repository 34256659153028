export function debounce(func, timeout = 300, onDebounceStart, onDebounceEnd) {
  let timeoutId = null
  return (...args) => {
    if (onDebounceStart) onDebounceStart()
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func(...args)
      if (onDebounceEnd) onDebounceEnd()
    }, timeout)
  }
}
